<template>
    <section class="app-ecommerce-details">
        <!-- Alert: No item found -->

        <b-overlay :show="formShow" rounded="sm" no-fade>
            <b-row>
                <b-col cols="12">
                    <!-- Vehiclle Image and Name -->
                    <b-card>
                        <b-tabs vertical nav-wrapper-class="nav-vertical" pills class="tab-class">
                            <b-tab active title="General Information" v-if="$Can('employees_information_tab')">
                                <b-card-body class="mt-0 pt-0">
                                    <b-alert
                                        variant="none"
                                        colour="none"
                                        :style="{ color: teamData.textColour ? teamData.textColour : '#FFFFFF', 'background-color': teamData.colour ? teamData.colour : '#5cb85c' }"
                                        show
                                    >
                                        <div class="alert-body d-flex justify-content-between">
                                            <div class="">
                                                <strong class="h3"> {{ teamData.title }} </strong>
                                                <span class="ml-1">
                                                    <b-badge
                                                        v-if="teamData.status == 'active'"
                                                        pill
                                                        :style="{ color: teamData.colour ? teamData.colour : '#5cb85c', 'background-color': teamData.textColour ? teamData.textColour : '#FFFFFF' }"
                                                        class="text-capitalize shadow"
                                                    >
                                                        Active
                                                    </b-badge>

                                                    <b-badge
                                                        v-if="teamData.status == 'deleted'"
                                                        pill
                                                        :style="{ color: teamData.colour ? teamData.colour : '#5cb85c', 'background-color': teamData.textColour ? teamData.textColour : '#FFFFFF' }"
                                                        class="text-capitalize shadow"
                                                    >
                                                        Removed
                                                    </b-badge>
                                                </span>
                                            </div>

                                            <span class="d-flex justify-content-end">
                                                <b-col v-show="$Can('employees_activate_btn')">
                                                    <b-button class="shadow" v-if="teamData.status != 'active'" @click="activateTeam" block variant="success">
                                                        Activate
                                                    </b-button>
                                                </b-col>

                                                <b-col v-show="$Can('employees_remove_btn')">
                                                    <b-button class="shadow" v-if="teamData.status != 'deleted'" @click="removeTeam" block variant="danger">
                                                        Remove
                                                    </b-button>
                                                </b-col>

                                                <!-- <b-col>
                          <b-button v-if="teamData.status != 'passive'" @click="terminateTeam" size="sm" block variant="warning">
                            Terminate
                          </b-button>
                        </b-col> -->

                                                <b-col v-show="$Can('employees_edit_btn')">
                                                    <b-button class="shadow" @click="editTeam" block variant="primary">
                                                        Edit
                                                    </b-button>
                                                </b-col>
                                            </span>
                                        </div>
                                    </b-alert>

                                    <b-tabs vertical nav-wrapper-class="nav-vertical" pills class="tab-class mt-1">
                                        <b-tab v-for="(data, index) in teamData.staff" :key="index">
                                            <template #title>
                                                <span v-if="data.role == 'Foreman'" class="mr-1">🤵🏻 <span v-if="data.status != 'A'">🟥</span> <span v-else>🟩</span></span>
                                                <span v-else class="mr-1">🧍🏻 <span v-if="data.status != 'A'">🟥</span> <span v-else>🟩</span></span>

                                                <span class="text-left">{{ data.full_name }}</span>
                                            </template>

                                            <b-card-body class="mt-0 pt-0">
                                                <table class="table table-bordered table-hover   w-100">
                                                    <tbody class="text-left">
                                                        <tr>
                                                            <td class="item-price bg-secondary text-white w-25">First name</td>
                                                            <td class="w-75">{{ data.name }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Last name</td>
                                                            <td>{{ data.surname }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Phone</td>
                                                            <td>{{ data.phone }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Email</td>
                                                            <td>{{ data.email }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Role</td>
                                                            <td>{{ data.role }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Date Of Birth</td>
                                                            <td>{{ dateFormat(data.dob) }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Address</td>
                                                            <td>{{ data.address1 }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Post Code</td>
                                                            <td>{{ data.post_code }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Phone</td>
                                                            <td>{{ data.phone }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">City</td>
                                                            <td>{{ data.city }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Address 2</td>
                                                            <td>{{ data.address2 }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Passport No</td>
                                                            <td>{{ data.passport_no }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Passport Expiry</td>
                                                            <td>{{ dateFormat(data.passport_expiry) }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Driving License</td>
                                                            <td>{{ data.driving_license }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">Forklift License</td>
                                                            <td>{{ data.forklift_license }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">CSCS</td>
                                                            <td>{{ data.cscs }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">NI Number</td>
                                                            <td>{{ data.ni_no }}</td>
                                                        </tr>

                                                        <tr>
                                                            <td class="item-price bg-secondary text-white">IPAF Expiry</td>
                                                            <td>{{ dateFormat(data.ipaf_expiry) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </b-card-body>
                                        </b-tab>
                                    </b-tabs>
                                </b-card-body>
                            </b-tab>

                            <b-tab title="Files" v-if="$Can('employees_files_tab')">
                                <b-card-body class="mt-0 pt-0">
                                    <b-row>
                                        <b-button v-show="$Can('employees_files_add_btn')" class="btn-icon ml-auto  mt-1 mb-1" @click="addNewFile()" variant="primary">
                                            Add New File
                                        </b-button>

                                        <table class=" table  table-bordered table-hover table-sm ">
                                            <b-col cols="12" v-if="teamData.id != null">
                                                <!-- user suggestion  -->
                                                <div v-for="(data, index) in files" :key="index" class="ml-5 mr-5 mt-2 mb-2">
                                                    <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                                                        <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                        <div class="user-page-info pr-1" style="width: 100%">
                                                            <span @click="fileEdit(data)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ data.fileName }} </span>
                                                        </div>

                                                        <!-- <b-button  variant="primary" @click="invoiceFile(data)" class="btn-icon ml-auto mr-1" style="padding: 1% 1%;" size="sm">
                          Invoice
                        </b-button> -->

                                                        <b-button
                                                            variant="primary"
                                                            target="_blank"
                                                            :href="fileURL + data.fileLink"
                                                            style="cursor:pointer;    margin-right:2px"
                                                            class="btn-icon ml-auto mr-1"
                                                            size="sm"
                                                        >
                                                            <feather-icon icon="DownloadIcon" />
                                                        </b-button>

                                                        <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                                            <feather-icon icon="XIcon" />
                                                        </b-button>
                                                    </div>

                                                    <div v-else class="d-flex justify-content-between align-items-center">
                                                        <b-img src="@/assets/images/url.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                        <div class="user-page-info pr-1" style="width: 100%">
                                                            <span @click="linkEdit(data)" style="cursor:pointer; font-size:1.1em">{{ data.fileName }} </span>
                                                        </div>
                                                        <b-button
                                                            variant="primary"
                                                            target="_blank"
                                                            :href="'https://' + data.fileLink"
                                                            style="cursor:alias;  margin-right:2px"
                                                            class="btn-icon ml-auto mr-1"
                                                            size="sm"
                                                            v-b-tooltip.hover
                                                            title="Go to url adress"
                                                        >
                                                            <feather-icon icon="ExternalLinkIcon" />
                                                        </b-button>

                                                        <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                                            <feather-icon icon="XIcon" />
                                                        </b-button>
                                                    </div>
                                                    <hr />
                                                </div>

                                                <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
                                                    No Files
                                                </div>
                                            </b-col>
                                        </table>
                                    </b-row>
                                </b-card-body>
                            </b-tab>

                            <b-tab title="Timeline" v-if="$Can('team_timeline_tab')">
                                <b-card v-if="teamData.timeline ? teamData.timeline.length : 0 > 0" style="overflow-y:scroll; height:500px">
                                    <app-timeline class="mt-2">
                                        <app-timeline-item v-for="item in teamData.timeline" :key="item.id" :variant="$func.varyant(item.status)" :icon="$func.varicon(item.status)">
                                            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                                <h6>{{ item.title }}</h6>
                                                <small class="text-muted">{{ $func.dateFormatTimeline(item.created_at) }}</small>
                                            </div>
                                            <small>{{ "By " + item.userNameSurname }}</small>
                                        </app-timeline-item>
                                    </app-timeline>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>

                <!-- <b-col cols="12" xl="2" md="3">
          <b-card>
            <b-card class="user-title" v-for="user in teams" :key="user.id">
              <div @click="viewTeam(user.id)">
                <span class="user-title"> {{ user.title }}</span>
              </div>
            </b-card>
          </b-card>
        </b-col> -->
            </b-row>
            <b-modal
                id="modal-file"
                cancel-variant="outline-secondary"
                ok-title="Save File"
                @ok="handleOk"
                @show="resetModal"
                @hidden="resetModal"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New File"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
                            <span class="switch-icon-left">
                                <feather-icon icon="FileIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="LinkIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
                        <b-form-file
                            @input="fileSelected"
                            type="file"
                            :state="fileState"
                            required
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            class="animate__animated animate__fadeIn"
                        />
                    </b-form-group>
                    <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
                        <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>

            <b-modal
                id="modal-file-update"
                cancel-variant="outline-secondary"
                ok-title="Change File"
                @ok="handleUpdate"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New File"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>
        </b-overlay>
    </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText } from "bootstrap-vue";
import moment from "moment";
import employeeStoreModule from "../employeeStoreModule";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

// import { avatarText } from '@core/utils/filter'

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BButton,
        BAvatar,
        BModal,
        BCardBody,
        BImg,
        BCardHeader,
        BCardTitle,
        BTabs,
        BCardText,
        BTab,
        AppTimeline,
        AppTimelineItem,
    },
    setup() {
        const EMPLOYEE_APP_STORE_MODULE_NAME = "employee";

        // Register module
        if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var teamData = {
            id: null,
            foreman: null,
            staffList: [],
            title: null,
        };

        const statusOptions = [
            { label: "Active", value: "active" },
            { label: "Passive", value: "passive" },
            { label: "Delete", value: "deleted" },
        ];

        return {
            teamData: teamData,

            userData: JSON.parse(localStorage.getItem("userData")),

            baseURL: store.state.app.baseURL,

            roleOptions: [],
            statusOptions,
            TeamId: null,
            formShow: false,
            permissions: [],
            teams: [],

            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            fetchAvailability: [],

            //FILES
            where: router.currentRoute.name,
            files: [],
            selectFileType: true,
            fileLink: null,
            modalId: null,
            modalFileName: null,
            modalFile: null,
            download: false,
            fileURL: store.state.app.fileURL,
            percentCompleted: 0,

            fetchTeams: [],
            totalRows: 0,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            config: {
                dateFormat: "d-m-Y",
                mode: "range",
            },

            faultTableColumns: [
                { key: "description", sortable: true, class: "text-center" },
                { key: "date", sortable: true, class: "text-center" },
                { key: "user", sortable: false, class: "text-center" },
            ],

            tableColumns: [
                { key: "availability", sortable: true, class: "text-center" },
                { key: "startDate", sortable: true, class: "text-center" },
                { key: "endDate", sortable: true, class: "text-center" },
            ],

            teamTableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "title", sortable: true, class: "text-center" },
                { key: "foreman", sortable: true, class: "text-center" },
                { key: "staff_count", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
            ],
        };
    },

    created() {
        this.getTeamById();
    },

    methods: {
        editTeam() {
            router.push({ name: "team-edit", params: { id: router.currentRoute.params.id } });
        },

        viewTeam(id) {
            this.formShow = true;
            store
                .dispatch("employee/fetchTeam", { id: id })
                .then((response) => {
                    this.teamData = response.data;
                    this.files = response.data.get_files;
                    this.teams = response.data.userList;

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
            router.push({ name: "team-view", params: { id: id } });
        },

        activateTeam() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Yes, activate the Team!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "A",
                        userId: router.currentRoute.params.id,
                    };
                    this.teamData.status = "active";

                    store.dispatch("employee/teamStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ Team Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                            this.getTeamById();
                        }
                    });
                }
            });
        },

        terminateTeam() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Yes, terminate the Team!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "P",
                        userId: router.currentRoute.params.id,
                    };
                    this.teamData.status = "passive";

                    store.dispatch("employee/teamStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ Team Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                            this.getTeamById();
                        }
                    });
                }
            });
        },

        removeTeam() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete the Team!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "D",
                        userId: router.currentRoute.params.id,
                    };

                    this.teamData.status = "deleted";

                    store
                        .dispatch("employee/teamStatus", tempData)
                        .then((response) => {
                            if (response.status == 200) {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Team Update Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });
                                this.getTeamById();
                            }
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Error",
                                text: "Team delete has failed. Please try again or contact support.",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },

        getTeamById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.TeamId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("employee/fetchTeam", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.teamData = response.data;
                            this.files = response.data.get_files;
                            this.teams = response.data.userList;

                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        dateFormat(val) {
            if (val != null) {
                moment.locale("en-US");
                return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
            } else {
                return "N/A";
            }
        },

        fileEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        linkEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        deleteFile(data) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.formShow = true;

                    axiosIns
                        .delete(`deleteTeamFile/${data.id}`)
                        .then((res) => {
                            var tempList = [];
                            this.files.forEach((element) => {
                                if (element.id != data.id) {
                                    tempList.push(element);
                                }
                            });

                            this.files = tempList;
                            this.formShow = false;
                            this.getTeamById();
                        })
                        .catch((error) => {
                            console.log("error delete ", error);

                            this.$swal({
                                title: "Error",
                                text: "Failed to delete the team file. Please try again or contact support.",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonText: "OK",
                            });
                        });
                }
            });
        },

        addNewFile() {
            this.$bvModal.show("modal-file");
        },

        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();

            if (this.selectFileType) {
                if (this.percentCompleted == 100) {
                    var data = {
                        team_id: router.currentRoute.params.id,
                        fileName: this.modalFileName,
                        fileLink: this.modalFile,
                        status: "A",
                    };

                    // Trigger submit handler
                    this.modalOkFile(data);
                }
            } else {
                var data = {
                    team_id: router.currentRoute.params.id,
                    fileName: this.modalFileName,
                    fileLink: this.fileLink,
                    status: "B",
                };
                this.modalOkFile(data);
            }
        },

        handleUpdate(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdate();
        },

        resetModal() {
            this.modalFileName = null;
            this.modalFile = null;
        },

        modalOkFile(data) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file");
            });

            this.formShow = true;
            axiosIns
                .post("saveTeamFile", data)
                .then((response) => {
                    this.files.push({
                        id: response.data.id,
                        fileName: response.data.fileName,
                        fileLink: response.data.fileLink,
                        status: response.data.status,
                    });

                    this.selectFileType = true;
                    this.fileLink = null;
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.formShow = false;
                    this.getTeamById();
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        modalUpdate(val) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file-update");
            });

            this.formShow = true;

            var data = {
                id: this.modalId,
                fileName: this.modalFileName,
                fileLink: this.modalFile,
            };

            axiosIns
                .put("updateTeamFile", data)
                .then((response) => {
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.files.forEach((file) => {
                        if (file.id == this.modalId) {
                            file.fileName = response.data.fileName;
                        }
                    });

                    this.formShow = false;
                    this.getTeamById();
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        fileSelected(event) {
            //  this.formShow = true;
            let formData = new FormData();
            formData.append("file", event);

            const config = {
                onUploadProgress: function(progressEvent) {
                    this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }.bind(this),
            };

            axiosIns
                .post("uploadTeamFile", formData, config)
                .then((response) => {
                    this.modalFile = response.data;

                    // this.tempImage = null;
                    // this.selectedImage = null;
                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        checkFormValidity() {
            const nameValid = this.$refs.fileRules.checkValidity();
            const fileValid = this.$refs.fileRules.checkValidity();

            return fileValid && nameValid;
        },
    },

    computed: {
        nameState() {
            return this.modalFileName != null && this.modalFileName != "" ? true : false;
        },

        fileState() {
            return this.modalFile != null && this.modalFile != "" ? true : false;
        },
        linkState() {
            let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            let regex = new RegExp(expression);
            let link = this.fileLink;
            if (link != null) {
                if (link.match(regex)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.btn {
    display: inline-block;
    font-weight: 400;
    color: #c7c6cc;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}

.btn-secondary {
    background-color: #94cf8f !important;
    border-color: #f5f5f5 !important;
}

.icon {
    stroke: black;
    fill: none;
    width: 36px;
    height: 36px;
    stroke-width: 0.6666666666666666;
}

.user-title {
    width: 100%;
    height: 100%;

    font-weight: bold;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    margin: auto;
}

.user-badge {
    text-align: center;
    vertical-align: middle;

    width: 50%;
    align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
    position: relative;
    display: flex;
    justify-content: start;
}

.tab-class >>> .nav-vertical {
    padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
    width: 100%;
}

.h3 {
    color: unset !important;
}

.shadow {
    box-shadow: 10px 10px 50px 10px rgb(165, 156, 156) !important;
}
</style>
