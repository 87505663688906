import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('employee', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchArchiveEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchArchiveEmployees', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    
    availabilityData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('availabilityData', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    exportExcel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportExcel', queryParams ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportPdf(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('exportPdf', queryParams, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    fetchTeams(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('team', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchArchiveTeams(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchArchiveTeams', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    foremanDropDown(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('foremanDropDown', {
          
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    staffDropDown(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('staffDropDown', {
        
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchEmployee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`employee/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else if (response.data.status == 'D') {
              response.data.status = 'deleted';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchTeam(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`team/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else if (response.data.status == 'D') {
              response.data.status = 'deleted';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('permissions')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('employee', employeeData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addTeam(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('team', teamData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`employee/${employeeData.id}`, employeeData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    employeeAvailability(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`employeeAvailability/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    userStatus(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`userStatus/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },




    updateTeam(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`team/${teamData.id}`, teamData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    teamStatus(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`teamStatus/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


  },
};
